//@ts-nocheck
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, CardGroup, Col, Container, Row } from 'react-bootstrap';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import {
    LongTermPortfolioType,
    PayoutRequestType,
    ProfitHistoryType,
    WithdrawalRequestType,
    WithdrawAvailibleProfitsType,
} from '../../../models/api/dashboard-model';
import { HistoryEnum } from '../../../models/enums';
import clientService from '../../../service/clientService';
import { formatCurrency } from '../../../utils/utils';
import WithdrawHistory from '../invest/components/withdraw-history';
import WithdrawalChart from './components/withdrawal-chart';
import WithdrawalModal from './components/withdrawal-modal';
import HoldWithdrawalModal from './components/hold-withdrawal-modal';

export type WithdrawalFormType = {
    iban: string;
    holdIban: string;
};

const MIN_AMOUT_VALUE = 10;

enum WithdrawalEnum {
    BONUSES = 'BONUSES',
    ACTIVE = 'ACTIVE',
}

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const Withdrawal: React.FC = () => {
    const { addToast } = useToasts();
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        trigger,
        reset,
    } = useForm<WithdrawalFormType>();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [payoutList, setPayoutList] = useState<PayoutRequestType[]>([]);
    const [approvedPayoutList, setApprovedPayoutList] = useState<PayoutRequestType[]>([]);
    const [history, setHistory] = useState<ProfitHistoryType>();

    const [portfolios, setPortfolios] = useState<LongTermPortfolioType[]>([]);

    const [availableProfits, setAvailableProfits] = useState<WithdrawAvailibleProfitsType>();

    const [portfolioId, setPortfolioId] = useState<number>();
    const [showHoldModal, setShowHoldModal] = useState<boolean>(false);

    const [withdrawalType, setWithdrawalType] = useState<WithdrawalEnum>(WithdrawalEnum.ACTIVE);
    const [withdrawwalAmount, setWithdrawalAmount] = useState<number>(0);

    const [bonusAmount, setBonusAmount] = useState<number>(0);
    const [activeAmount, setActiveAmount] = useState<number>(0);

    const approvedBonusPayout = useMemo(() => {
        return approvedPayoutList.filter((item) => item.type !== 'ACTIVE_PROFIT');
    }, [approvedPayoutList]);

    const approvedActivePayout = useMemo(() => {
        return approvedPayoutList.filter((item) => item.type === 'ACTIVE_PROFIT');
    }, [approvedPayoutList]);

    const totalActualProfits = useMemo(() => {
        return availableProfits ? availableProfits.activeAmount + availableProfits.bonusAmount : 0;
    }, [availableProfits]);

    const disableBonusWithdraw = useMemo(() => {
        if (!availableProfits) return true;
        return availableProfits.bonusAmount < MIN_AMOUT_VALUE;
    }, [availableProfits]);

    const disableActiveWithdraw = useMemo(() => {
        if (!availableProfits) return true;
        return availableProfits.activeAmount < MIN_AMOUT_VALUE;
    }, [availableProfits]);

    const loadPayoutRequest = (page: number) => {
        clientService.getPayoutRequest({ page, size: 10 }).then((res) => {
            setPayoutList(res.data.requests.content);
        });
    };

    const loadApprovedPayoutRequests = () => {
        clientService.getApprovedPayouts().then((res) => {
            setApprovedPayoutList(res.data.requests.content);
        });
    };

    const loadProfitHistory = () => {
        clientService.getProfitsHistory(HistoryEnum.YEAR).then((res) => {
            setHistory(res.data);
        });
    };

    const getAmount = () => {
        clientService.getAvailableProfits().then((res) => {
            setAvailableProfits(res.data);
            // setAvailableProfits({ bonusAmount: 1200, activeAmount: 980 });
        });
    };

    const getPortfolios = () => {
        clientService.getLongTermPortfolios().then((res) => {
            setPortfolios(res.data.portfolios);
        });
    };

    useEffect(() => {
        getAmount();
        loadApprovedPayoutRequests();
        loadProfitHistory();
        getPortfolios();
        loadPayoutRequest();
    }, []);

    const openWithdrawModal = (am: number, type: WithdrawalEnum) => {
        setWithdrawalAmount(am);
        setWithdrawalType(type);
        setShowModal(true);
    };

    const openHoldWithdrawModal = (id: number) => {
        setPortfolioId(id);
        setShowHoldModal(true);
    };

    const withdraw = ({ iban }: WithdrawalFormType) => {
        if (iban?.trim() === '') return addToast('IBAN required', { appearance: 'error' });
        setLoading(true);
        const amountData = {
            ...(withdrawalType === WithdrawalEnum.ACTIVE
                ? { activeAmount: withdrawwalAmount, bonusesAmount: 0 }
                : { activeAmount: 0, bonusesAmount: withdrawwalAmount }),
        };
        const data = {
            iban,
            ...amountData,
        };
        clientService
            .withdrawProfits(data)
            .then(() => {
                reset();
                setShowModal(false);
                // setRangeValue(0);
                addToast(t('toast.withdrawalSuccess'));
                loadPayoutRequest(0);
                getAmount();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const holdWithdraw = ({ holdIban }: WithdrawalFormType) => {
        if (!portfolioId) return setShowHoldModal(false);
        if (holdIban?.trim() === '') return addToast('IBAN required', { appearance: 'error' });
        setLoading(true);

        const data = {
            iban: holdIban,
            paymentId: portfolioId,
        };
        clientService
            .holdWithdraw(data)
            .then((res) => {
                reset();
                setShowHoldModal(false);
                // setRangeValue(0);
                addToast(t('toast.withdrawalSuccess'));
                loadPayoutRequest(0);
                getAmount();
                getPortfolios();
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    addToast(err.response.data.message, { appearance: 'error' });
                }
            })
            .finally(() => {
                setLoading(false);
                setShowHoldModal(false);
            });
    };

    const renderTime = (dimension: string, time: number) => {
        return (
            <div className="time-wrapper">
                <div className="time">{time}</div>
                <div className="dimension">{dimension}</div>
            </div>
        );
    };

    const getTimeSeconds = (time: number): number => (minuteSeconds - time) | 0;
    const getTimeMinutes = (time: number): number => ((time % hourSeconds) / minuteSeconds) | 0;
    const getTimeHours = (time: number): number => ((time % daySeconds) / hourSeconds) | 0;
    const getTimeDays = (time: number): number => (time / daySeconds) | 0;

    const renderTimer = (item: LongTermPortfolioType) => {
        const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
        const endTime = new Date(item.nextWithdrawal).getTime() / 1000;

        const remainingTime = endTime - stratTime;
        const days = Math.ceil(remainingTime / daySeconds);
        const daysDuration = days * daySeconds;

        if (remainingTime < 0) {
            return (
                <div className="hold-wrapper">
                    <Button className="sign-in-btn " onClick={() => openHoldWithdrawModal(item.payment.id)}>
                        Withdrawal
                    </Button>
                </div>
            );
        }

        return (
            <Row className="justify-content-center mobile-pie-chart-direction">
                <Col className="my-3 bg-chart-color" sm={12} md="auto">
                    <CountdownCircleTimer
                        duration={daysDuration}
                        size={80}
                        trailColor={'#060b17'}
                        strokeWidth={10}
                        strokeLinecap="square"
                        initialRemainingTime={remainingTime}
                        colors={[['#bc2c79', 0.33]]}
                    >
                        {({ elapsedTime }) => renderTime('days', getTimeDays(daysDuration - elapsedTime))}
                    </CountdownCircleTimer>
                </Col>
                <Col className="my-3 bg-chart-color" sm={12} md="auto">
                    <CountdownCircleTimer
                        isPlaying
                        duration={daySeconds}
                        size={80}
                        trailColor={'#060b17'}
                        strokeWidth={10}
                        strokeLinecap="square"
                        initialRemainingTime={remainingTime % daySeconds}
                        onComplete={(totalElapsedTime) => [remainingTime - totalElapsedTime > hourSeconds]}
                        colors={[['#66478f', 0.33]]}
                    >
                        {({ elapsedTime }) => renderTime('hours', getTimeHours(daySeconds - elapsedTime))}
                    </CountdownCircleTimer>
                </Col>
                <Col className="my-3 bg-chart-color" sm={12} md="auto">
                    <CountdownCircleTimer
                        isPlaying
                        colors={[['#828aba', 0.33]]}
                        duration={hourSeconds}
                        size={80}
                        trailColor={'#060b17'}
                        strokeWidth={10}
                        strokeLinecap="square"
                        initialRemainingTime={remainingTime % hourSeconds}
                        onComplete={(totalElapsedTime) => [remainingTime - totalElapsedTime > minuteSeconds]}
                    >
                        {({ elapsedTime }) => renderTime('minutes', getTimeMinutes(hourSeconds - elapsedTime))}
                    </CountdownCircleTimer>
                </Col>
                <Col className="my-3 bg-chart-color" sm={12} md="auto">
                    <CountdownCircleTimer
                        isPlaying
                        duration={minuteSeconds}
                        size={80}
                        trailColor={'#060b17'}
                        strokeWidth={10}
                        strokeLinecap="square"
                        initialRemainingTime={remainingTime % minuteSeconds}
                        onComplete={(totalElapsedTime) => [remainingTime - totalElapsedTime > 0]}
                        colors={[['#1baadb', 0.33]]}
                    >
                        {({ elapsedTime }) => renderTime('seconds', getTimeSeconds(elapsedTime))}
                    </CountdownCircleTimer>
                </Col>
            </Row>
        );
    };

    return (
        <>
            <div className="invest-page-container">
                <p className="disclaimer-withdrawal-styles">{t('withdrawal.withdrawalDuringInfo')}</p>
                <CardGroup>
                    <Card className="spacing-withdrawal-card">
                        <div className="withdrawal-actual py-5">
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col className="text-white total-actual-text">{t('withdrawal.withdrawalTotalActualProfit')}</Col>
                                        </Row>
                                        <Row>
                                            <Col className="text-white">
                                                <span className="total-actual-amount">{formatCurrency(totalActualProfits)}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col className="text-white total-actual-text">{t('withdrawal.withdrawalLastWithdrawal')}</Col>
                                        </Row>
                                        <Row>
                                            <Col className="text-white">
                                                <span className="total-actual-amount">{formatCurrency(approvedPayoutList?.[0]?.amount)}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div className="info-space-withdraw"></div>
                                <div className="">
                                    <Card.Title className="text-white">Info</Card.Title>
                                    <Card.Text className="text-white withdraw-time-amount">{t('withdrawal.withdrawalInfoFirstTitle')}</Card.Text>
                                    <hr className="text-white" />
                                    <Card.Text className="text-white withdraw-time-amount">
                                        {t('withdrawal.withdrawalInfoSecondTitle')} {MIN_AMOUT_VALUE}€.
                                    </Card.Text>
                                </div>
                            </Card.Body>
                        </div>
                    </Card>
                    <Card className="spacing-withdrawal-card">
                        <div className="bonus-profits text-center py-5">
                            <Card.Body>
                                <Card.Title className="text-white withdraw-profits-text">{t('withdrawal.withdrawalBonusProfitsTitle')}</Card.Title>
                                <Card.Text className="text-muted withdraw-profits-text-subtitle">
                                    {t('withdrawal.withdrawalActualReferralProfitTitle')}
                                </Card.Text>
                                <div className="mt-5">
                                    <span className="text-white withdraw-actual-amount">{formatCurrency(availableProfits?.bonusAmount)}</span>
                                </div>
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <span className="text-white mt-5 scale-amount">{formatCurrency(bonusAmount)}</span>
                                    <input
                                        className="mt-2 w-100"
                                        disabled={disableBonusWithdraw}
                                        value={bonusAmount}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setBonusAmount(+e.target.value);
                                        }}
                                        step={0.5}
                                        max={availableProfits?.bonusAmount}
                                        type="range"
                                    />
                                    <Button
                                        className="sign-in-btn mt-5"
                                        disabled={bonusAmount < MIN_AMOUT_VALUE}
                                        onClick={() => openWithdrawModal(bonusAmount, WithdrawalEnum.BONUSES)}
                                    >
                                        Withdrawal
                                    </Button>
                                </div>
                            </Card.Body>
                        </div>
                    </Card>
                    <Card>
                        <div className="bonus-profits text-center py-5">
                            <Card.Body>
                                <Card.Title className="text-white withdraw-profits-text">{t('withdrawal.withdrawalActiveTradingTitle')}</Card.Title>
                                <Card.Text className="text-muted withdraw-profits-text-subtitle">
                                    {t('withdrawal.withdrawalActualTradeProfit')}
                                </Card.Text>
                                <div className="mt-5">
                                    <span className="text-white withdraw-actual-amount">{formatCurrency(availableProfits?.activeAmount)}</span>
                                </div>
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <span className="text-white mt-5 scale-amount">{formatCurrency(activeAmount)}</span>
                                    <input
                                        className="mt-2 w-100"
                                        type="range"
                                        value={activeAmount}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setActiveAmount(+e.target.value);
                                        }}
                                        step={0.5}
                                        max={availableProfits?.activeAmount}
                                        disabled={disableActiveWithdraw}
                                    />
                                    <Button
                                        className="sign-in-btn mt-5"
                                        disabled={activeAmount < MIN_AMOUT_VALUE}
                                        onClick={() => openWithdrawModal(activeAmount, WithdrawalEnum.ACTIVE)}
                                    >
                                        Withdrawal
                                    </Button>
                                </div>
                            </Card.Body>
                        </div>
                    </Card>
                </CardGroup>
                <div className="withdrawal-pending-approval mt-4">
                    <Row>
                        <Col md="auto">
                            <div className="d-flex flex-column">
                                <span className="withdrawal-pending-title">{t('withdrawal.withdrawalPendingApproval')}</span>
                                {payoutList.map((item, index) => (
                                    <span key={index} className="withdrawal-bonus-title">
                                        <b>{formatCurrency(item.amount)}</b>
                                        <span>{item.type === 'ACTIVE_PROFIT' ? 'Active trading' : 'Bonus profits'} </span>
                                    </span>
                                ))}
                            </div>
                        </Col>
                        <Col className="pending-border" md="auto">
                            <hr />
                        </Col>
                        <Col>
                            <p className="withdrawal-pending-title m-0">Info</p>
                            <span>{t('withdrawal.withdrawalPendingApprovalInfo')}</span>
                        </Col>
                    </Row>
                </div>
                <Row className="invest-widgets-grid-container d-flex">
                    {/* <InvestChart /> */}
                    <Col xs={12} lg="9">
                        <WithdrawalChart history={history} />
                    </Col>
                    <Col xs={12} lg="3">
                        {approvedBonusPayout.length > 0 && (
                            <WithdrawHistory data={approvedBonusPayout as WithdrawalRequestType[]} title="Withdrawal history Bonus profits" />
                        )}

                        <div>
                            {approvedActivePayout.length > 0 && (
                                <WithdrawHistory data={approvedActivePayout as WithdrawalRequestType[]} title="Withdrawal history Active profits" />
                            )}
                        </div>
                    </Col>
                </Row>
                <p className="disclaimer-withdrawal-styles">{t('withdrawal.withdrawalDisclaimer')}</p>
                {portfolios.map((item, index) => (
                    <div className="hold-container mt-3" key={index}>
                        <Row className="justify-content-center align-items-center">
                            <Col sm={12} md="auto" lg={2}>
                                <Container>
                                    <p className="my-invest-text m-0">My invest: {moment(item.createdAt).format('DD.MM.YYYY')}</p>
                                    <span className="text-white my-invest-amount">
                                        <b>{formatCurrency(item.initialAmount)}</b>
                                    </span>
                                </Container>
                            </Col>
                            <Col sm={12} md="auto" lg={2}>
                                <Container>
                                    <p className="my-invest-text m-0">Actual hold profit</p>
                                    <span className="text-white my-invest-amount">
                                        <b>{formatCurrency(item.currentAmount)}</b>
                                    </span>
                                </Container>
                            </Col>
                            <Col sm={12} md="auto" lg={3}>
                                <Container>
                                    <p className="text-white my-invest-description">{t('withdrawal.withdrawalHoldDesc')}</p>
                                </Container>
                            </Col>
                            <Col>{renderTimer(item)}</Col>
                        </Row>
                        {/* <Row>
                            <Col className="p-4">{renderTimer(item.nextWithdrawal)}</Col>
                        </Row> */}
                    </div>
                ))}
            </div>

            {availableProfits && (
                <WithdrawalModal
                    amount={withdrawwalAmount}
                    isOpened={showModal}
                    onClose={() => setShowModal(false)}
                    register={register}
                    errors={errors}
                    trigger={trigger}
                    watch={watch}
                    setValue={setValue}
                    handleSend={handleSubmit(withdraw)}
                    loading={loading}
                />
            )}
            <HoldWithdrawalModal
                isOpened={showHoldModal}
                onClose={() => setShowHoldModal(false)}
                register={register}
                errors={errors}
                trigger={trigger}
                watch={watch}
                setValue={setValue}
                handleSend={handleSubmit(holdWithdraw)}
                loading={loading}
            />
        </>
    );
};

export default Withdrawal;
