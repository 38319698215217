import moment from 'moment';
import React, { useContext } from 'react';
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ClientInvestmentItemType, ClientProfileType, PortfolioType } from '../../../models/api/dashboard-model';
import UserLogo from './../../../components/user-logo';
import { formatCurrency, generateShortUserName } from './../../../utils/utils';
import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';

type PropsType = {
    eventKey: string;
    item: ClientProfileType;
    portfolios?: PortfolioType[];
    note?: string;
    loadPortfolios: (eventKey: number) => void;
    investments: ClientInvestmentItemType[];
    loadInvestments: (id: number) => void;
    handleUpdeteNote: (username: string) => void;
    handleNoteChange: (note: string) => void;
};

const ClientItem: React.FC<PropsType> = ({
    eventKey,
    item,
    portfolios,
    note,
    loadPortfolios,
    handleUpdeteNote,
    handleNoteChange,
    investments,
    loadInvestments,
}) => {
    const { t } = useTranslation();

    return (
        <div className="affiliate-item">
            <div className="item-info-container">
                <div className="user-profile">
                    <div className="user-img">
                        <UserLogo name={generateShortUserName(item.name, item.surname)} small />
                    </div>
                    <div className="user-info">
                        <div className="primary-text">{`${item.name} ${item.surname}`}</div>
                        <div className="secondary-text"></div>
                    </div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{item.email}</div>
                    <div className="secondary-text"></div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{item.phone}</div>
                    <div className="secondary-text"></div>
                </div>
                {/* <div></div> */}
                <div className="affilate-action">
                    <ContextAwareToggle eventKey={eventKey} callback={() => loadInvestments(item.id)} />
                </div>
            </div>
            <Accordion.Collapse eventKey={eventKey}>
                <Accordion defaultActiveKey="0">
                    <div>
                        {item.employeeNotes?.map((i, index) => (
                            <div key={index} className="note-item" dangerouslySetInnerHTML={{ __html: i }}></div>
                        ))}
                    </div>
                    <div className="employee-note">
                        <textarea key={item.id} className="note-area" value={note} onChange={(e) => handleNoteChange(e.target.value)} rows={6} />
                        <div className="note-btn-wrapper">
                            <button className="base-btn" onClick={() => handleUpdeteNote(item.username)}>
                                {t('common.save')}
                            </button>
                        </div>
                    </div>
                    <div className="item-portfolio-container">
                        {investments.map((invest, index) => (
                            <>
                                <div className="item-info-container five-in-row pb-3" key={index}>
                                    <div className="affilate-amount">
                                        <div className="secondary-text align-items-center">{`0${index + 1}`}</div>
                                    </div>
                                    <div className="affilate-amount">
                                        <div className="primary-text">{formatCurrency(invest.amount)}</div>
                                        <div className="secondary-text">{}</div>
                                    </div>
                                    <div className="affilate-amount">
                                        <div className="primary-text">{invest.type ? t(`enums.investType.${invest.type}`) : ''}</div>
                                        <div className="secondary-text">{moment(invest.createdAt).format('DD.MM.YYYY')}</div>
                                    </div>
                                    <div className="affilate-amount">
                                        <div className="secondary-text align-items-center">
                                            <ContextArrowAwareToggle eventKey={`port${invest.id}`} callback={() => loadPortfolios(invest.id)} />
                                        </div>
                                    </div>
                                    <div></div>
                                </div>
                                <Accordion.Collapse eventKey={`port${invest.id}`} className="pb-3 ">
                                    <>
                                        {portfolios?.map((port, i) => (
                                            <div className="item-info-container p-0" key={i}>
                                                <div className="affilate-amount"></div>
                                                <div className="affilate-amount">
                                                    <div className="secondary-text">{port.position.type}</div>
                                                </div>
                                                <div className="affilate-amount">
                                                    <div className="secondary-text">{formatCurrency(port.initialAmount)}</div>
                                                </div>
                                            </div>
                                        ))}

                                        {portfolios?.length === 0 && (
                                            <div className="portfolio-empty">
                                                <span>{t('affilate.empty-list')}</span>
                                            </div>
                                        )}
                                    </>
                                </Accordion.Collapse>
                            </>
                        ))}
                        {investments.length === 0 && (
                            <div className="portfolio-empty">
                                <span>{t('affilate.empty-list')}</span>
                            </div>
                        )}
                    </div>
                </Accordion>
            </Accordion.Collapse>
        </div>
    );
};

type ContextAwareToggleProps = {
    eventKey: string;
    callback?: (eventKey: string) => void;
    children?: JSX.Element | string;
};

function ContextAwareToggle({ eventKey, callback }: ContextAwareToggleProps) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button type="button" onClick={decoratedOnClick}>
            {isCurrentEventKey ? 'Hide' : 'Show2'}
        </button>
    );
}

function ContextArrowAwareToggle({ eventKey, callback }: ContextAwareToggleProps) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <div role="button" className="p-2" onClick={decoratedOnClick}>
            {isCurrentEventKey ? <ChevronUp /> : <ChevronDown />}
        </div>
    );
}

export default ClientItem;
