import { AxiosResponse } from 'axios';
import moment from 'moment';
import {
    CountriesResponseType,
    CryptoPaymentRequestType,
    CryptoPaymentResponseType,
    InboxAlertResponseType,
    UserResponseType,
} from '../models/api/auth-models';
import {
    AffiliatesHeadType,
    AffiliatesResponseType,
    ClientStatisticPortfolioType,
    HoldWithdrawRequestType,
    InboxResponseType,
    InvestmentHistoryResponseType,
    InvestmentResponseType,
    LongTermPortfoliosResponseType,
    PagebleReqeustType,
    PayoutRequestDataType,
    PayoutRequestResponseType,
    ProfileResponseType,
    ProfitHistoryType,
    ProfitsResponseType,
    PrortfolioResponseType,
    ReferralUsersResponseType,
    ReinvestReferralType,
    SimplifiedAffiliatesResponseType,
    TopAffiliatesResponseType,
    UserHistoryResponseType,
    UsersCountResponseType,
    WithdrawAmountResponse,
    WithdrawAvailibleProfitsType,
} from '../models/api/dashboard-model';
import { HistoryEnum, InboxTypeDrowpdownEnum } from '../models/enums';
import { ContactFormType } from '../models/models';
import axiosInstance from './axiosConfig';

const clientService = {
    sendContactForm: (data: ContactFormType): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/send-contact-form', data);
    },

    getAllCountries: (): Promise<AxiosResponse<CountriesResponseType>> => {
        return axiosInstance.post('/country-list');
    },
    getUser: (): Promise<AxiosResponse<UserResponseType>> => {
        return axiosInstance.get('/get-user');
    },

    getProfits: (): Promise<AxiosResponse<ProfitsResponseType>> => {
        return axiosInstance.get('/get-profits');
    },
    getProfitsHistory: (term: HistoryEnum): Promise<AxiosResponse<ProfitHistoryType>> => {
        return axiosInstance.get('/get-profit-history', { params: { term } });
    },
    inviteReferal: (email: string): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post(`/invite-referral`, { to: email });
    },
    paymentRequest: (token: string, amount: number): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/buy-portfolio', { token, amount });
    },
    getReferrals: (): Promise<AxiosResponse<ReferralUsersResponseType>> => {
        return axiosInstance.get('/get-referrals');
    },
    getPortfolios: (): Promise<AxiosResponse<PrortfolioResponseType>> => {
        return axiosInstance.get('/get-portfolios');
    },
    getHistory: (term: HistoryEnum): Promise<AxiosResponse<UserHistoryResponseType>> => {
        return axiosInstance.get('/get-history', { params: { term } });
    },
    // withdrawProfits: (amount: number): Promise<AxiosResponse<unknown>> => {
    //     return axiosInstance.post('/withdraw', { reinvest: amount });
    // },
    payoutRequest: (data: PayoutRequestDataType): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/request-payout', data);
    },
    getPayoutRequest: (data: PagebleReqeustType): Promise<AxiosResponse<PayoutRequestResponseType>> => {
        return axiosInstance.get('/get-payout-requests', { params: data });
    },
    getWithdrawAmount: (): Promise<AxiosResponse<WithdrawAmountResponse>> => {
        return axiosInstance.get('/withdraw-amount');
    },
    getTotalUsers: (): Promise<AxiosResponse<UsersCountResponseType>> => {
        return axiosInstance.get('/get-total-users');
    },
    getInvestment: (): Promise<AxiosResponse<InvestmentResponseType>> => {
        return axiosInstance.get('/get-investments');
    },
    getInvestmentHistory: (): Promise<AxiosResponse<InvestmentHistoryResponseType>> => {
        return axiosInstance.get('/get-investments-history');
    },
    getProfile: (): Promise<AxiosResponse<ProfileResponseType>> => {
        return axiosInstance.get('/get-profile');
    },
    updateProfile: (data: FormData): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/edit-profile', data);
    },
    getWithdrawProfits: (): Promise<AxiosResponse<number>> => {
        return axiosInstance.get('/get-withdraw-profits');
    },
    getAvailableProfits: (): Promise<AxiosResponse<WithdrawAvailibleProfitsType>> => {
        return axiosInstance.get('/get-available-profits');
    },
    withdrawProfits: (data: PayoutRequestDataType): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/withdraw-profits', data);
    },
    holdWithdraw: (data: HoldWithdrawRequestType): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/withdraw-hold', data);
    },
    getReinvestProfits: (): Promise<AxiosResponse<ReinvestReferralType[]>> => {
        return axiosInstance.get('/get-reinvest-profits');
    },
    reinvestProfits: (amount: number): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/reinvest-profits', { amount });
    },
    acceptTerms: (): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/accept-terms');
    },
    getAffiliatesHead: (): Promise<AxiosResponse<AffiliatesHeadType>> => {
        return axiosInstance.get('/get-affiliates-head');
    },
    getAffiliates: (id: number, line: number, filter?: number): Promise<AxiosResponse<AffiliatesResponseType>> => {
        return axiosInstance.get('/get-affiliates', { params: { id, line, filter } });
    },
    getSimplifiedAffiliates: (filter: string): Promise<AxiosResponse<SimplifiedAffiliatesResponseType>> => {
        return axiosInstance.get('/get-simplified-affiliates', { params: { filter } });
    },
    getTopAffiliates: (): Promise<AxiosResponse<TopAffiliatesResponseType>> => {
        return axiosInstance.get('/get-top-affiliates');
    },
    getInbox: (
        pageable: PagebleReqeustType,
        dateFilter?: string,
        filter?: InboxTypeDrowpdownEnum,
        fetchInbox?: boolean,
    ): Promise<AxiosResponse<InboxResponseType>> => {
        const date = dateFilter && moment(dateFilter).format('yyyy-MM-DD');
        return axiosInstance.get('/get-inbox', { params: { ...pageable, dateFilter: date, filter, fetchInbox } });
    },
    getInboxAlert: (): Promise<AxiosResponse<InboxAlertResponseType>> => {
        return axiosInstance.get('/get-inbox-alert');
    },
    getInboxContent: (fileKey: string): Promise<AxiosResponse<string>> => {
        return axiosInstance.get('/get-inbox-content', { params: { fileKey } });
    },
    rateMessage: (id: number): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/rate-message', null, { params: { id } });
    },
    downloadFile: (fileName: string): Promise<AxiosResponse<string>> => {
        return axiosInstance.get('/file/download', { params: { fileName }, responseType: 'blob' });
    },
    getCountries: (): Promise<AxiosResponse<CountriesResponseType>> => {
        return axiosInstance.post('/country-list');
    },
    readInbox: (id: number): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/read-message', null, { params: { id } });
    },
    getApprovedPayouts: (): Promise<AxiosResponse<PayoutRequestResponseType>> => {
        return axiosInstance.get('/get-approved-payout-requests', { params: { size: 100 } });
    },
    getPortfoliosByPayment: (paymentId: number): Promise<AxiosResponse<ClientStatisticPortfolioType[]>> => {
        return axiosInstance.get('/get-portfolios-by-payment', { params: { paymentId } });
    },
    getLongTermPortfolios: (): Promise<AxiosResponse<LongTermPortfoliosResponseType>> => {
        return axiosInstance.get('/get-long-term-portfolios');
    },
    buyWithCrypto: (data: CryptoPaymentRequestType): Promise<AxiosResponse<CryptoPaymentResponseType>> => {
        return axiosInstance.post('/buy-with-crypto', data);
    },
};

export default clientService;
