import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BankLogo, SeifLogo } from '../../../../assets/images/index';
import { WithdrawAvailibleProfitsType } from '../../../../models/api/dashboard-model';
import { BaseFormType } from '../../../../models/models';
import { WithdrawalFormType } from '../withdrawal';
import BaseButton from './../../../../components/common/base-button';
import BaseInput from './../../../../components/common/base-input';
import LoadingIndicator from './../../../../components/loading-indicator';
import { useFormRules } from './../../../../hooks/use-form-rules';

type PropsType = BaseFormType<WithdrawalFormType> & {
    amount?: number;
    loading: boolean;
    handleSend: () => void;
    isOpened: boolean;
    onClose: () => void;
};

const WithdrawalModal: React.FC<PropsType> = ({ amount, isOpened, onClose, loading, handleSend, register, errors, trigger, watch, setValue }) => {
    const { t } = useTranslation();
    const { requiredTrimed, formRules } = useFormRules();
    const [canReplace, setCanReplace] = useState<boolean>(true);

    const iban: string = (watch && watch(`iban`)) ?? '';

    useEffect(() => {
        if (iban) {
            const preview = iban
                .toUpperCase()
                .replace(/\W/gi, '')
                .replace(/(.{4})/g, '$1 ');

            canReplace && setValue && setValue(`iban`, preview);
        }
    }, [iban, setValue, canReplace]);

    return (
        <>
            <LoadingIndicator visible={loading} />
            <Modal show={isOpened} size="sm" onHide={onClose} centered>
                <Modal.Body className="auth-modal">
                    <div className="auth-container auth-padding">
                        <div className="auth-logo">
                            <img src={SeifLogo} alt="" />
                        </div>
                        <div>
                            {amount && <div className="text-muted">Withdrawal amount {amount}€</div>}
                            <BaseInput
                                {...register('iban', { ...formRules.required, ...formRules.iban })}
                                errors={errors}
                                icon={BankLogo}
                                type="text"
                                label={t('withdrawal.iban')}
                                trigger={trigger}
                                onKeyDown={(e) => {
                                    if (e.nativeEvent.key === 'Backspace' || e.nativeEvent.key === 'Delete') {
                                        setCanReplace(false);
                                    } else {
                                        setCanReplace(true);
                                    }
                                }}
                            />
                            {/* <BaseInput
                                {...register('card', {})}
                                errors={errors}
                                icon={UsdtLogo}
                                type="text"
                                label={t('withdrawal.usdt')}
                                trigger={trigger}
                            /> */}

                            <BaseButton onClick={handleSend} className="auth-sign-in-btn btn-center mt-4">
                                {t('auth.send')}
                            </BaseButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default WithdrawalModal;
